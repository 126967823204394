/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import MDXLayout from "/builds/thiagovilla/thiagovilla.com/src/templates/default";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    em: "em",
    del: "del",
    code: "code",
    blockquote: "blockquote",
    br: "br",
    h2: "h2",
    span: "span",
    ul: "ul",
    li: "li",
    ol: "ol",
    div: "div",
    pre: "pre",
    img: "img",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    h1: "h1",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    sup: "sup",
    input: "input",
    hr: "hr",
    section: "section"
  }, _provideComponents(), props.components), {Color} = _components;
  if (!Color) _missingMdxReference("Color", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This is based on CommonMark's ", React.createElement(_components.a, {
    href: "https://commonmark.org/help/",
    title: "https://commonmark.org/help/"
  }, "Markdown reference"), "."), "\n", React.createElement(_components.p, null, "This is ", React.createElement(_components.strong, null, "bold"), ", ", React.createElement(_components.em, null, "italic"), ", ", React.createElement(_components.del, null, "strikethrough"), " and ", React.createElement(_components.code, null, "inline code"), "."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "This is a blockquote", React.createElement(_components.br), "\n", "...with a line break"), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Another blockquote"), "\n"), "\n", React.createElement(_components.h2, {
    id: "table-of-contents"
  }, React.createElement(_components.a, {
    href: "#table-of-contents",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Table of contents"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "List"), "\n", React.createElement(_components.li, null, "List"), "\n", React.createElement(_components.li, null, "List"), "\n"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "One"), "\n", React.createElement(_components.li, null, "Two"), "\n", React.createElement(_components.li, null, "Three"), "\n"), "\n", React.createElement(_components.div, {
    className: "remark-highlight"
  }, React.createElement(_components.pre, {
    className: "language-js"
  }, React.createElement(_components.code, {
    className: "language-js"
  }, React.createElement(_components.span, {
    className: "token keyword"
  }, "let"), " a ", React.createElement(_components.span, {
    className: "token operator"
  }, "="), " ", React.createElement(_components.span, {
    className: "token number"
  }, "1"), React.createElement(_components.span, {
    className: "token punctuation"
  }, ";"), "\n", React.createElement(_components.span, {
    className: "token keyword"
  }, "let"), " b ", React.createElement(_components.span, {
    className: "token operator"
  }, "="), " ", React.createElement(_components.span, {
    className: "token number"
  }, "2"), React.createElement(_components.span, {
    className: "token punctuation"
  }, ";"), "\n", React.createElement(_components.span, {
    className: "token console class-name"
  }, "console"), React.createElement(_components.span, {
    className: "token punctuation"
  }, "."), React.createElement(_components.span, {
    className: "token method function property-access"
  }, "log"), React.createElement(_components.span, {
    className: "token punctuation"
  }, "("), "a ", React.createElement(_components.span, {
    className: "token operator"
  }, "+"), " b", React.createElement(_components.span, {
    className: "token punctuation"
  }, ")"), React.createElement(_components.span, {
    className: "token punctuation"
  }, ";"), " ", React.createElement(_components.span, {
    className: "token comment"
  }, "// 3"), "\n"))), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://media.graphassets.com/dRtTVdHTD2pxUaG2ByKu",
    alt: "sundance.jpg",
    title: "sundance.jpg"
  })), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Food"), React.createElement(_components.th, null, "Color"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Apple"), React.createElement(_components.td, null, "Red")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Avocado"), React.createElement(_components.td, null, "Green")))), "\n", React.createElement(_components.h1, {
    id: "heading-1"
  }, React.createElement(_components.a, {
    href: "#heading-1",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Heading 1"), "\n", React.createElement(_components.h2, {
    id: "heading-2"
  }, React.createElement(_components.a, {
    href: "#heading-2",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Heading 2"), "\n", React.createElement(_components.h3, {
    id: "heading-3"
  }, React.createElement(_components.a, {
    href: "#heading-3",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Heading 3"), "\n", React.createElement(_components.h4, {
    id: "heading-4"
  }, React.createElement(_components.a, {
    href: "#heading-4",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Heading 4"), "\n", React.createElement(_components.h5, {
    id: "heading-5"
  }, React.createElement(_components.a, {
    href: "#heading-5",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Heading 5"), "\n", React.createElement(_components.h6, {
    id: "heading-6"
  }, React.createElement(_components.a, {
    href: "#heading-6",
    "aria-hidden": "true",
    tabIndex: "-1"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Heading 6"), "\n", React.createElement(_components.p, null, "This is a footnote", React.createElement(_components.sup, null, React.createElement(_components.a, {
    href: "#user-content-fn-1",
    id: "user-content-fnref-1",
    "data-footnote-ref": true,
    "aria-describedby": "footnote-label"
  }, "1"))), "\n", React.createElement(_components.ul, {
    className: "contains-task-list"
  }, "\n", React.createElement(_components.li, {
    className: "task-list-item"
  }, React.createElement(_components.input, {
    type: "checkbox",
    disabled: true
  }), " ", "to do"), "\n", React.createElement(_components.li, {
    className: "task-list-item"
  }, React.createElement(_components.input, {
    type: "checkbox",
    checked: true,
    disabled: true
  }), " ", "done"), "\n"), "\n", React.createElement(_components.p, null, "Autolink test: example.com, ", React.createElement(_components.a, {
    href: "http://www.exmaple.com"
  }, "www.exmaple.com"), ", ", React.createElement(_components.a, {
    href: "https://example.com"
  }, "https://example.com"), ", ", React.createElement(_components.a, {
    href: "mailto:email@example.com"
  }, "email@example.com")), "\n", React.createElement(_components.p, null, "From Garreft Fick's ", React.createElement(_components.a, {
    href: "https://www.ficksworkshop.com/blog/pre-code-kbd-samp-var",
    title: "https://www.ficksworkshop.com/blog/pre-code-kbd-samp-var"
  }, "website"), ":"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "kbd"), " tag: ", React.createElement("kbd", null, "User input, traditionally keyboard")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "samp"), " tag: ", React.createElement("samp", null, "Sample (program) output")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "var"), " tag: ", React.createElement("var", null, "Variable name (math, code)")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "mark"), " tag: ", React.createElement("mark", null, "Highlighted text")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "From ", React.createElement(_components.a, {
    href: "http://html5doctor.com/ins-del-s/",
    title: "http://html5doctor.com/ins-del-s/"
  }, "HTML5 Doctor"), ":"), "\n", React.createElement(_components.p, null, "This text ", React.createElement("del", null, "is original"), " ", React.createElement("ins", null, "was replaced"), "."), "\n", React.createElement("xmp", null, "<xmp> is disallowed. <XMP> is also disallowed."), "\n", React.createElement(Color, {
    value: "green"
  }, "This is green"), "\n", "\n", React.createElement(_components.section, {
    "data-footnotes": true,
    className: "footnotes"
  }, React.createElement(_components.h2, {
    className: "sr-only",
    id: "footnote-label"
  }, "Footnotes"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, {
    id: "user-content-fn-1"
  }, "\n", React.createElement(_components.p, null, "A footnote is a note in the footer. ", React.createElement(_components.a, {
    href: "#user-content-fnref-1",
    "data-footnote-backref": true,
    className: "data-footnote-backref",
    "aria-label": "Back to content"
  }, "↩")), "\n"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
